import { t } from '@lingui/macro';

import { api } from 'actions/utils';

import { pushDangerToaster, pushSuccessToaster } from 'components/ui/Toaster';

import { forgetUserGlobally, setAnalyticsUserGlobally } from 'utils/analytics';

export const actionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  WHOAMI_REQUEST: 'WHOAMI_REQUEST',
  WHOAMI_SUCCESS: 'WHOAMI_SUCCESS',
  ADMIN_LOG_AS_REQUEST: 'ADMIN_LOG_AS_REQUEST',
  ADMIN_LOG_AS_SUCCESS: 'ADMIN_LOG_AS_SUCCESS',
  ADMIN_LOG_AS_FAILURE: 'ADMIN_LOG_AS_FAILURE',
  SAVE_USER_PROFILE_REQUEST: 'SAVE_USER_PROFILE_REQUEST',
  SAVE_USER_PROFILE_SUCCESS: 'SAVE_USER_PROFILE_SUCCESS',
  SAVE_USER_PROFILE_FAILURE: 'SAVE_USER_PROFILE_FAILURE',
};

export const loginSuccess = (userData) => {
  setAnalyticsUserGlobally(userData.id, userData.organization);
  return {
    type: actionTypes.LOGIN_SUCCESS,
    user: userData,
  };
};

export const login = (username, password) => async (dispatch) => {
  dispatch({ type: actionTypes.LOGIN_REQUEST });
  try {
    const response = await api.post('/user/login', { username, password });
    dispatch(loginSuccess(response.data.user));
  } catch (error) {
    dispatch({ type: actionTypes.LOGIN_FAILURE });
    return Promise.reject();
  }
  return Promise.resolve();
};

export const receiveLogout = () => (dispatch) => {
  dispatch({
    type: actionTypes.LOGOUT_SUCCESS,
  });
  return Promise.resolve();
};

export const logoutUser = () => async (dispatch) => {
  dispatch({ type: actionTypes.LOGOUT_REQUEST });
  try {
    await api.get('/user/logout');
    forgetUserGlobally();
    dispatch(receiveLogout());
  } catch (error) {
    dispatch({ type: actionTypes.LOGOUT_FAILURE });
  }
  return Promise.resolve();
};

export const checkAuthentication = () => async (dispatch) => {
  dispatch({ type: actionTypes.WHOAMI_REQUEST });
  let response;
  try {
    response = await api.get('user/whoami');

    // Set user id to GA and Amplitude globally
    setAnalyticsUserGlobally(response.data.id, response.data.organization);

    dispatch({ type: actionTypes.WHOAMI_SUCCESS, user: response.data });
  } catch (error) {
    dispatch(receiveLogout());
    return Promise.reject();
  }
  return Promise.resolve();
};

export const adminLogAs = (username) => async (dispatch, getState) => {
  forgetUserGlobally();
  dispatch({ type: actionTypes.ADMIN_LOG_AS_REQUEST });
  let response;
  try {
    response = await api.post('admin/login-as', { username });
    dispatch({
      type: actionTypes.ADMIN_LOG_AS_SUCCESS,
      user: response.data.user,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.ADMIN_LOG_AS_FAILURE,
      error: error.response.status,
    });
    return Promise.reject();
  }
  return Promise.resolve();
};

export /**
 * Set user id to GA and Amplitude globally if user is logged in.
 * NB : user id is reset when logging/logging out/logging as admin.
 */
const maybeSetAnalyticsUser = () => async (dispatch, getState) => {
  const { user: { id: userId } = {} } = getState().user;
  if (userId) {
    setAnalyticsUserGlobally(userId);
  }
};

export const requestUserProfile = () => async (dispatch) => {
  dispatch({ type: actionTypes.WHOAMI_REQUEST });
  try {
    const response = await api.get('user/whoami');
    dispatch({ type: actionTypes.WHOAMI_SUCCESS, user: response.data });
  } catch (error) {
    dispatch({ type: actionTypes.WHOAMI_FAILURE });
  }
};

export const saveUserProfile = (pseudonym) => async (dispatch) => {
  dispatch({ type: actionTypes.SAVE_USER_PROFILE_REQUEST });
  try {
    const response = await api.put(
      '/user/profile',
      { pseudonym },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      dispatch({ type: actionTypes.SAVE_USER_PROFILE_SUCCESS });
    } else {
      dispatch({ type: actionTypes.SAVE_USER_PROFILE_FAILURE });
      pushDangerToaster(t`user-profile-save-error`);
    }
  } catch (error) {
    dispatch({ type: actionTypes.SAVE_USER_PROFILE_FAILURE });
    pushDangerToaster(t`user-profile-save-unexpected-error`);
  }
  pushSuccessToaster(t`user-profile-update-success`);
};
