import { css } from 'styled-components';

import { darken, lighten, readableColor, transparentize } from 'polished';

// Dimensions
export const spaceXSmall = '0.1rem';
export const spaceSmall = '0.2rem';
export const spaceSmaller = '0.35rem';
export const spaceNormal = '0.5rem';
export const spaceNormalLarge = '0.6rem';
export const spaceMedium = '1rem';
export const spaceMediumLarge = '1.5rem';
export const spaceLarge = '2rem';
export const spaceXLarge = '3rem';
export const spaceMassive = '5rem';

export const borderRadius = '8px';
export const borderColorLight = 'rgba(0, 0, 0, 0.05)';
export const ctaBorderRadius = '6px'; // '30px';

export const smallButtonMinWidth = '5rem';
export const inputMinWidth = '10rem';
export const smallInputMaxWidth = '20rem';
export const smallInputMinWidth = '10.5rem';
export const inputBorderWidth = '1px';

export const sentimentCellMaxWidth = '65px';
export const sentimentCellMinWidth = '56px';

// Layout and dimensions
export const logoWidth = '2.4rem'; // Logo width
export const logoPreviewMaxWidth = '48px';
export const logoPreviewMaxHeight = '48px';
export const navbarHeight = '3.2rem'; // Navbar height
export const sidebarWidth = '4.5rem';
export const breadcrumbsHeight = '3.5rem';
export const dashboardHeaderMinHeight = '180px';
export const tooltipMaxWidth = 270;
export const loaderHeight = '2px';
export const listMaxHeight = '200px';

export const pageMaxWidth = '1600px'; // Maximum width for the app's pages content
export const compactPageMaxWidth = '780px';
export const simplePageMaxWidth = '1080px';
export const buttonMinWidth = '6rem'; // minimum buttons width
export const inputMaxWidth = '38rem'; // maximum input width
export const scrollBarWidth = '15px';

export const thinScrollbarCss = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const buttonMaxHeight = '7rem';
export const buttonMinHeight = '1rem';

// Table and list dimensions
export const defaultDateColumnWidth = 120;
export const defaultColumnMinWidth = 150;
export const defaultVolumeColumnMaxWidth = 150;
export const defaultColumnMaxWidth = 300;

// Animation
export const transitionBase = 'all 0.15s ease-out';

// Colors
export const accentColor = '#007DFF'; // New potential color: #0073ea
export const accentColorLight = lighten(0.15, accentColor);
export const accentColorClear = transparentize(0.2, accentColor);
export const accentColorClearMedium = transparentize(0.6, accentColor);
export const accentColorMedium = lighten(0.42, accentColor);
export const accentColorTransparent = transparentize(0.6, accentColorMedium);
export const accentColorLighter = '#EDF6FF';
export const accentColorLightest = '#f5faff';

export const colorSecondary = '#454e62';
export const colorSecondaryLighter = lighten(0.16, colorSecondary);
export const colorSecondaryLightest = lighten(0.55, colorSecondary);
export const colorPrimary = '#003974';

export const colorComplementary = '#37392E';

export const colorWhite = '#FFFFFF';
export const colorWhiteLightlyTransparent = transparentize(0.4, colorWhite);

export const colorSuccess = '#14ba89'; // New potential color: #19E5A9
export const colorDanger = '#FF5656'; // New potential color: #FF8181
export const colorDangerLighter = lighten(0.2, colorDanger);
export const colorDangerLightest = '#FFF0F0'; // #fff0f0  '#FFF8F8'
export const colorWarning = '#E57530'; // New potential color: #FF884D
export const colorYellowWarning = '#FFC107';
export const colorBlack = '#000000';
export const colorDarkGrey = '#282727';
export const colorGrey = '#747474'; // New potential color: #707070
export const colorGreyMedium = '#c6ccd2';
export const colorLightGrey = '#E9E9E9';
export const colorLighterGrey = '#F7F7F7';
export const colorLightestGrey = lighten(0.075, colorLightGrey);
export const backgroundColorBase = lighten(0.065, colorLightGrey);

// Sentiment colors
export const absoluteMaxColor = '#00DCAD';
export const absoluteMidColor = '#F0DECD';
export const absoluteMidColorStroke = '#CEB095'; // '#debfa2';

export const absoluteMinColor = '#8121E8';
export const absoluteMidNegativeColor = '#be88d9';
export const absoluteMidPositiveColor = '#78DDBD';

export const volumeColor = '#FF9500';
export const volumeStrokeColor = '#FF5B0B';
export const minVolumeGradientColor = '#FFC63F'; // Used to build color gradient for volume
export const minVolumeColor = '#ffece3'; // Used to build color gradient for volume

export const VolumGradient = css`
  ${({ valueRatio }) => `linear-gradient(
  to right,
  ${minVolumeGradientColor},
  ${volumeColor},
  ${(valueRatio && valueRatio > 0.7 && volumeStrokeColor) || volumeColor}
)`}
`;

export const wordCloudAbsoluteMaxColor = absoluteMaxColor;
export const wordCloudAbsoluteMidColor = absoluteMidColor;
export const wordCloudAbsoluteMinColor = absoluteMinColor;

export const TOP_COLORS = [
  '#00dcad',
  '#4CE6C5',
  '#7FEDD5',
  '#B2F4E6',
  '#E0FAF4',
];

export const BOTTOM_COLORS = [
  '#8D37EA',
  '#A663EE',
  '#BF90F3',
  '#D9BCF8',
  '#F2E8FC',
];

export const ENTITIES_COLORS = [
  '#EDD544',
  '#770787',
  '#E46E26',
  '#97CAE4',
  '#BD1D39',
  '#FF9AA2',
  '#2C6C6A',
  '#8A8654',
  '#7F8084',
  '#10AC84',
  '#EA5AA7',
  '#4870B5',
  '#DE8865',
  '#4A0D95',
  '#FFA726',
  '#94B73F',
  '#9A9AFF',
  '#900389',
  '#DDF10F',
  '#7F1914',
];

export const competitionEntityColors = [
  '#5c0a1e',
  '#bb2649',
  '#e28e95',
  '#f1bbbe',
  '#ED2F5C',
  '#F07894',
  '#f6d1d3',
  '#da7781',
  '#fbe8e9',
];

export const colorPickerPalette = [
  '#007DFF',
  '#FF5500',
  '#FFC63F',
  '#55FF00',
  '#00EAFF',
  '#007427',
  '#AA00FF',
  '#FF00AA',
];

export const minOntologyColor = '#E4C2C1';
export const maxOntologyColor = '#582a2f';

export const sourceEntitiesMinColor = minVolumeGradientColor;
export const sourceEntitiesMaxColor = volumeStrokeColor;

// Campaign colors

export const colorSurvey = '#F4BE91';
export const colorFeedback = '#D5DDFF';

/**
 * Construct a generator from an array of items.
 *
 * @export
 * @param {Array<String>} colors an array of hexadecimal colors.
 * @returns a generator of hexadecimal colors.
 */
export function getColorGenerator(colors) {
  function* generatorFunction() {
    const nColors = colors.length;
    let i = -1;
    while (true) {
      i += 1;
      yield colors[i % nColors];
    }
  }
  return generatorFunction();
}

export const TEXT_BACKGROUNDS = [
  '#C9D9FB',
  '#CBCDE5',
  '#CDC1CF',
  '#CFB7B9',
  '#D1ACA4',
];

const nTextBackground = TEXT_BACKGROUNDS.length;
export const getTextBackground = (wordIndex) =>
  TEXT_BACKGROUNDS[wordIndex % nTextBackground];

export const accentLightenRatio = 0.045;

export const colorPrimaryHover = lighten(accentLightenRatio, colorPrimary);
export const colorSuccessHover = lighten(accentLightenRatio, colorSuccess);
export const colorWarningHover = lighten(accentLightenRatio, colorWarning);
export const colorDangerHover = lighten(accentLightenRatio, colorDanger);
export const colorWhiteHover = lighten(accentLightenRatio, colorWhite);
export const accentColorHover = lighten(2 * accentLightenRatio, accentColor);

const disabledDarkenRatio = 0.1;

export const colorPrimaryDisabled = darken(disabledDarkenRatio, colorPrimary);
export const colorSuccessDisabled = darken(disabledDarkenRatio, colorSuccess);
export const colorWarningDisabled = darken(disabledDarkenRatio, colorWarning);
export const colorDangerDisabled = darken(disabledDarkenRatio, colorDanger);
export const accentColorDisabled = lighten(
  3 * disabledDarkenRatio,
  accentColor
);

// Functional colors
export const scoreColor = colorDarkGrey;
export const lightBorderStyle = `solid ${inputBorderWidth} ${borderColorLight}`;

export const backgroundColorHover = darken(
  accentLightenRatio,
  backgroundColorBase
);
export const logoMaxHeightPx = 75;
export const logoMaxHeight = `${logoMaxHeightPx}px`;

export const textHighlightGradient = `linear-gradient(180deg,${colorWhite} -50%, ${lighten(
  0.45,
  accentColor
)} 50%, ${colorWhite} 200%)`;

export const secondaryGradient = `linear-gradient(90deg, ${colorPrimary} 0%, rgba(0,100,204,1) 86%, rgba(0,106,216,1) 100%)`;

// Group labels
export const hierarchyGroupNameMaxCharacters = 50;

// Dates
export const dateFormat = 'L';
export const dateTimeDateFormat = 'll';
export const fnsDateTimeFormat = 'PPpp';
export const dateTimeTimeFormat = 'LT';
export const dateTimeFormat = `${dateTimeDateFormat},${dateTimeTimeFormat}`;

export const baseChartMargin = 8;
export const getChartMargins = (
  withBottomLegend = false,
  withSideLegend = true
) => ({
  // Leave place for legend
  top: withSideLegend ? 25 : baseChartMargin,
  bottom: withBottomLegend ? 10 : baseChartMargin,
  right: 2 * baseChartMargin,
  left: 2 * baseChartMargin,
});

// Treemap

export const treemapHoverFrameWidth = 3;
export const treemapHoverFrameColor = accentColor;
// Padding for treemap tooltip text (in both x and y directions)
export const treemapTooltipPadding = 10;
// Distance (in both x and y directions) between the cursor and the tooltip's closest corner
export const treemapTooltipCursorDistance = 20;
export const treemapTooltipHeight = 130;
export const treemapTooltipWidth = tooltipMaxWidth;
export const treemapColorScaleHeight = 10;
export const treemapColorScaleWidth = 150;
export const treemapBreadcrumbsMinHeight = 50;

// Svg

export const svgCharLength = 9;
export const svgCharLengthFontSizeSmall = 6;
// Passed all tests - 7 does not - 7.5 is too much
export const svgCharWidthFontSizeSmall = 7.35;
export const textSpanHeight = 15;

// Competitive positioning chart
export const competitivePositioningMaxCharsXAxis = 25;
export const competitivePositioningXAxisAngle = -25;
export const competitivePositioningMaxCharsYAxis = 25;
export const competitivePositioningLegendWidth = 105;
export const competitivePositioningLegendHeight = 140;

export const baseBoxShadow = 'rgb(43 34 51 / 8%) 0px 1px 4px';
export const strongBoxShadow = 'rgba(100, 100, 111, 0.5) 0px 7px 30px 0px';
export const primaryBoxShadow = `0 -1px 5px ${transparentize(
  0.5,
  accentColor
)}`;

export const selectedBoxShadow = `inset 0 0 3px ${transparentize(
  0.75,
  accentColor
)}`;
export const selectedDarkBoxShadow = `inset 0 0 9px ${colorLightGrey}`;
export const selectedLightBoxShadow = 'inset 0 0 9px rgb(43 34 51 / 5%)';

export const inheritedSelectedBoxShadow = (myColor) => `0 0 3px ${myColor}`;

export const nMaxComparativeGroups = 5;

// Font
export const fontFamilySansSerif = 'Muli, sans-serif';
export const fontFamilyTitle = 'Muli, sans-serif';
export const fontColorBase = '#393D3F';
export const fontColorLighter = '#797777';
export const fontColorLightest = '#aeaeae';

export const fontSizeXSmall = '0.6rem';
export const fontSizeSmall = '0.75rem';
export const fontSizeSmaller = '0.84rem';
export const fontSizeBase = '0.88rem';
export const fontSizeMedium = '1.067rem';

export const fontSizeLarge = '1.15rem';
export const fontSizeLarger = '1.28rem';
export const fontSizeXLarge = '1.424rem';
export const lineHeightSizeXLarge = '1.8rem';
export const fontSizeBig = '1.602rem';
export const lineHeightSizeBig = '2.2rem';
export const largeIconFontSize = '2.3rem';
export const iconOpacityLighter = 0.75;

// Line heights
export const lineHeightSmall = '1.4';
export const lineHeightLarge = '1.8';

export const fontWeightExtraBold = 800;
export const fontWeightBold = 700;
export const fontWeightSemiBold = 600;
export const fontWeightMedium = 500;
export const fontWeightBase = 400;
export const fontWeightLight = 300;
export const fontWeightExtraLight = 200;

export const disabledOpacity = 0.15;
export const fontColorDisabled = lighten(0.65, fontColorBase);
export const textMaxWidth = '46rem';

// Dashboard kpis
export const cardMaxWidth = 190;
export const cardMinHeight = 230; // Unused for now
export const dashboardKpiMinHeight = 65; // Unused for now

// Recharts visualizations

// Use this property to reduce empty space around y axes when values are number
// with max 5 digits
export const chartHeight = 250;
export const chartMinWidth = 220;
export const YAxisWidth = 45;
export const xAxisMinTickGap = 20;
// Padding between Y axis and first/last tick
export const xAxisPadding = 5;
export const chartFontSize = fontSizeSmaller;
export const chartFontColor = '#666363';
export const tooltipOpacity = 0.85;
export const cartesianGridStrokeColor = '#ECEBEB';
export const barSize = 10;
export const barRadius = 3;

export /**
 * Compute a radius (in px) for scatter dots based on a ratio value (i.e. ranging from 0 to 1) which:
 * - use a linear combination with constant factors to have a predictable and common scatter dot size
 * - factors were defined to limit minimum and maximum dot sizes
 * - a value of 0 has a radius of 7 (minimum accessible radius)
 * - a value of 1 has a radius of 25 (maximum accessible radius)
 *
 * @param {str} key the key to access ratio value in `item`
 * @param {Object} item the item to retrieve ratio value from
 * @return {Number} the radius in px
 */
const getPercentScatterRadius = (key) => (item) => item[key] * 18 + 7;

export const CONCEPTS_LEVEL_COLOR_MAP = {
  0: lighten(0.7, colorPrimary),
  1: lighten(0.65, colorPrimary),
  2: lighten(0.6, colorPrimary),
  3: lighten(0.55, colorPrimary),
  4: lighten(0.5, colorPrimary),
  5: lighten(0.45, colorPrimary),
};

export const hoverClickableCss = css`
  ${({ disabled }) =>
    disabled
      ? ''
      : `
      cursor: pointer;
      &:hover {
        background: ${colorLighterGrey};
      }
      `};
`;
export const activeClickableCss = css`
  ${({ disabled, active, selected }) =>
    (!disabled &&
      (active || selected) &&
      css`
        &,
        :hover {
          background: ${accentColorTransparent};
          border-color: ${accentColorClearMedium};
        }
      `) ||
    ''};
`;

export const getReadableColor = (color) => {
  try {
    return readableColor(color, fontColorBase, colorWhite, true);
  } catch (e) {
    return fontColorBase;
  }
};

export const emptyCategoryGradientId = 'notCompletedPattern';
export const emptyCategoryGradientUrl = `url(#${emptyCategoryGradientId})`;

export const avatarSize = '28px';
export const avatarFontSize = '15px';
