import { api, getQueryString } from 'actions/utils';

import { getColorGenerator } from 'assets/style/variables';

/**
 * Add a color to each entity of a list.
 *
 * @param {*} entities the entities
 * @param {*} colorsArrayOrGenerator an array or generator of hex colors.
 * @returns the entities enriched with colors.
 */
export const makeColorMap = (entities, colorsArrayOrGenerator) => {
  const colorsGenerator =
    colorsArrayOrGenerator && colorsArrayOrGenerator.length !== undefined
      ? getColorGenerator(colorsArrayOrGenerator)
      : colorsArrayOrGenerator;
  const colorMap = {};
  entities.forEach((entity) => {
    colorMap[entity.id] = colorsGenerator.next().value;
  });
  return colorMap;
};
export const actionTypes = {
  FETCH_ONTOLOGIES_REQUEST: 'FETCH_ONTOLOGIES_REQUEST',
  FETCH_ONTOLOGIES_FAILURE: 'FETCH_ONTOLOGIES_FAILURE',
  FETCH_ONTOLOGIES_SUCCESS: 'FETCH_ONTOLOGIES_SUCCESS',
  FETCH_FACET_ENTITIES_REQUEST: 'FETCH_FACET_ENTITIES_REQUEST',
  FETCH_FACET_ENTITIES_FAILURE: 'FETCH_FACET_ENTITIES_FAILURE',
  FETCH_FACET_ENTITIES_SUCCESS: 'FETCH_FACET_ENTITIES_SUCCESS',
  FETCH_PRODUCT_HIERARCHIES_REQUEST: 'FETCH_PRODUCT_HIERARCHIES_REQUEST',
  FETCH_PRODUCT_HIERARCHIES_FAILURE: 'FETCH_PRODUCT_HIERARCHIES_FAILURE',
  FETCH_PRODUCT_HIERARCHIES_SUCCESS: 'FETCH_PRODUCT_HIERARCHIES_SUCCESS',
  FETCH_TAG_SETS_REQUEST: 'FETCH_TAG_SETS_REQUEST',
  FETCH_TAG_SETS_FAILURE: 'FETCH_TAG_SETS_FAILURE',
  FETCH_TAG_SETS_SUCCESS: 'FETCH_TAG_SETS_SUCCESS',
  FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_ADMIN_ENTITIES_REQUEST: 'FETCH_ADMIN_ENTITIES_REQUEST',
  FETCH_ADMIN_ENTITIES_SUCCESS: 'FETCH_ADMIN_ENTITIES_SUCCESS',
  FETCH_ADMIN_ENTITIES_FAILURE: 'FETCH_ADMIN_ENTITIES_FAILURE',
};

const fetchOntologies =
  (viewFacetId = undefined, cacheKey = null) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.FETCH_ONTOLOGIES_REQUEST,
    });
    const queryString = viewFacetId ? `?facet=${viewFacetId}` : '';
    let response;
    try {
      response = await api.get(`ontologies${queryString}`);
    } catch (error) {
      return dispatch({
        type: actionTypes.FETCH_ONTOLOGIES_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
        cacheKey,
      });
    }
    return dispatch({
      type: actionTypes.FETCH_ONTOLOGIES_SUCCESS,
      data: response.data,
      viewFacetId,
    });
  };

export const maybeFetchOntologies =
  (viewFacetId) => async (dispatch, getState) => {
    const cacheKey = `ontologies-vf=${viewFacetId}`;
    if (!getState().entities.loaded.includes(cacheKey)) {
      dispatch(fetchOntologies(viewFacetId, cacheKey));
    }
  };

export const fetchEntities = (viewFacetId) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.FETCH_FACET_ENTITIES_REQUEST,
  });
  let response;
  try {
    response = await api.get(`facets/${viewFacetId}/entities`);
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_FACET_ENTITIES_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_FACET_ENTITIES_SUCCESS,
    viewFacetId,
    sourceGroup: response.data.source_group,
    sources: response.data.source_group.items,
    productHierarchies: response.data.hierarchies,
    productHierarchyGroups: response.data.hierarchy_groups,
    // productHierarchyGroups: [
    //   response.data.base_product_hierarchy_group,
    //   ...(response.data.comparative_product_hierarchy_groups
    //     ? response.data.comparative_product_hierarchy_groups
    //     : []),
    // ],
  });
};

export const fetchAdminEntities = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.FETCH_ADMIN_ENTITIES_REQUEST });
  let response;
  try {
    response = await api.get(`/admin_entities`);
  } catch (error) {
    return dispatch({ type: actionTypes.FETCH_ADMIN_ENTITIES_FAILURE }, error);
  }

  return dispatch({
    type: actionTypes.FETCH_ADMIN_ENTITIES_SUCCESS,
    adminEntities: response.data,
  });
};

export const fetchProductHierarchies =
  (viewFacetId) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.FETCH_PRODUCT_HIERARCHIES_REQUEST,
    });
    let response;
    try {
      // Setting a high limit to get all hiearchies when acceptable
      response = await api.post(
        `product_hierarchy/search?${getQueryString({
          limit: 8000,
          facet: viewFacetId,
        })}`,
        {}
      );
    } catch (error) {
      return dispatch({
        type: actionTypes.FETCH_PRODUCT_HIERARCHIES_FAILURE,
        // Commented out because it is non serializable  thus not accepted by redux
        // error,
      });
    }
    return dispatch({
      type: actionTypes.FETCH_PRODUCT_HIERARCHIES_SUCCESS,
      productHierarchies: response.data,
    });
  };

export const maybeFetchProductHierarchies =
  (viewFacetId = undefined) =>
  async (dispatch, getState) => {
    // NB : if another component queries some (but not all) product hierarchies
    // this method may not be triggered correctly
    if (
      !getState().entities.loaded.includes(`productHierarchies(${viewFacetId})`)
    ) {
      // Views have not been initialized properly (for instance because user was not logged in).
      return dispatch(fetchProductHierarchies(viewFacetId));
    }
    return undefined;
  };

const fetchTagSets = () => async (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_TAG_SETS_REQUEST,
  });
  let response;
  try {
    response = await api.get('/tag-sets');
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_TAG_SETS_FAILURE,
      // Commented out because it is non serializable  thus not accepted by redux
      // error,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_TAG_SETS_SUCCESS,
    tagSets: response.data,
  });
};

export const maybeFetchTagSets = () => async (dispatch, getState) => {
  if (!getState().entities.loaded.includes('tagSets')) {
    dispatch(fetchTagSets());
  }
};

const fetchUsers = () => async (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_USERS_REQUEST,
  });
  let response;
  try {
    response = await api.get('users');
  } catch (error) {
    return dispatch({
      type: actionTypes.FETCH_USERS_FAILURE,
    });
  }
  return dispatch({
    type: actionTypes.FETCH_USERS_SUCCESS,
    users: response.data,
  });
};

export const maybeFetchUsers = () => async (dispatch, getState) => {
  if (!getState().entities.loaded.includes('users')) {
    dispatch(fetchUsers());
  }
};
